import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BGImg from 'gatsby-background-image'
import { Carousel } from 'react-bootstrap'

import './index.css'

import Layout from '../components/layout'
import SEO from '../components/seo'

const PolitikPage = () => {
  const data = useStaticQuery(graphql`
    query {
      guggi: file(relativePath: { eq: "politik/guggi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='CSU Neutraubling' />
      <BGImg
        className='jumbotron jumbotron-fluid'
        fluid={data.guggi.childImageSharp.fluid}>
        <div className='container'>
          <h1 className='display-4 text-light' style={{ fontWeight: 'normal' }}>
            Politik
          </h1>
          <h2 className='text-primary' style={{ textShadow: '0 0 10px black' }}>
            Mit Herz und Verstand
          </h2>
        </div>
      </BGImg>
      <div className='container my-5'>
        <p>
          Die CSU steht für eine nachhaltige Sachpolitik. Wir wollen Ihre
          Lebensqualität mit notwendigen ökonomischen und ökologischen Faktoren
          in Einklang bringen, um Neutraubling weiter auf Erfolgsspur zu halten.
          Für uns ist klar: Jede Forderung muss auch realisierbar sein. Wir
          arbeiten nicht mit leeren Worthülsen oder Verboten, die keine
          Akzeptanz bei der Bevölkerung finden. Wir planen Maßnahmen, die
          finanzierbar sind und so einen Mehrwert für jeden Bewohner unserer
          Stadt bilden.
        </p>
        <p>
          Wir haben deshalb das Zukunftsprogramm „Neutraubling2020“ geschaffen,
          mit dem unsere Stadt sicher und realisierbar planen kann, notwendige
          soziale und umweltpolitische Aspekte nicht aus den Augen verloren
          werden und wir in den nächsten sechs Jahren gerne Politik mit Ihnen
          gestalten wollen.
        </p>
      </div>
      <ControlledCarousel />
    </Layout>
  )
}

function ControlledCarousel() {
  const [index, setIndex] = React.useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      interval={null}
      className='text-dark'>
      <Carousel.Item>
        <div className='jumbotron jumbotron-fluid'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7'>
                <h3 className='mb-3'>Verkehr</h3>
                <div className='lead'>
                  <ul>
                    <li>
                      keinen Durchgangs- und Fernverkehr auf der Süd- /
                      Ostumgehung
                    </li>
                    <li>
                      keine Westumgehung Gärtnersiedlung, dafür Verlagerung
                      Verkehr auf B15
                    </li>
                    <li>
                      Entlastung Staatsstraße 2145 durch Verlagerung Verkehr auf
                      B15
                    </li>
                    <li>
                      Verkehrsentlastung im südlichen Landkreis durch
                      großräumige Verkehrsführung um die Gemeinden herum
                    </li>
                    <li>
                      Anschluss Neutraubling an eine Stadtbahn nach Regensburg
                    </li>
                    <li>
                      Weiterführung Stadtbahn in den nördlichen Bereich
                      Neutraubling
                    </li>
                    <li>
                      kostenloser ÖPNV innerhalb der Stadtgrenzen Neutraublings
                    </li>
                    <li>
                      Verknüpfung ÖPNV mit Stadtbahn durch optimierte Anbindung
                    </li>
                    <li>
                      Verbesserter ÖPNV innerhalb Neutraubling mit Ausbau
                      Anbindung neue Wohn- und Baugebiete
                    </li>
                    <li>
                      direkte Anbindung Neutraubling an Hochschulcampus und
                      Universitätsklinik Regensburg durch ÖPNV
                    </li>
                    <li>
                      Erstellung eines Kleinbus- / Pendelbuskonzepts für die
                      Innenstadt Neutraubling
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-4 offset-md-1'>
                <img
                  className='img-fluid'
                  style={{ width: '100%' }}
                  src='politik/verkehr.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='jumbotron jumbotron-fluid'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7'>
                <h3 className='mb-3'>Wohnraum</h3>
                <div className='lead'>
                  <ul>
                    <li>
                      Förderung bezahlbarer Wohnraum vorrangig durch
                      Unterstützung von Bauprojekten „an Einkommen orientiertes
                      Bauen“ und als Option durch Schaffung von Wohnraum in
                      städtischer Hand
                    </li>
                    <li>Schaffung von „Mehrgenerationen-Wohnquartieren“</li>
                    <li>
                      Schaffung seniorengerechter Wohnraum und seniorengerechter
                      Wohnquartiere
                    </li>
                    <li>
                      zügige Ausweisung von Wohngebieten nördlich
                      Fürst-Johannes-Ring, Ostpreußenstraße, Steinäckerweg
                    </li>
                    <li>
                      Nachverdichtung von Wohngebieten, wo sinnvoll und seitens
                      Infrastruktur möglich
                    </li>
                  </ul>
                </div>{' '}
              </div>
              <div className='col-md-4 offset-md-1'>
                <img
                  className='img-fluid'
                  style={{ width: '100%' }}
                  src='politik/wohnraum.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='jumbotron jumbotron-fluid'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7'>
                <h3 className='mb-3'>Gestaltung Innenstadt</h3>
                <div className='lead'>
                  <ul>
                    <li>
                      Sanierung und Schaffung deutlich besserer
                      Aufenthaltsqualität Sudetenstraße / „Schlangenbau“ mit:
                      <ul>
                        <li>Durchgang zum Neutraublinger See</li>
                        <li>
                          ebenerdiger, behindertengerechter Zugang zu
                          Erdgeschoss „Schlangenbau“
                        </li>
                        <li>
                          Aufwertung von Freiflächen mit Sitzgelegenheiten
                        </li>
                        <li>Mischnutzung Gastronomie, Kleingewerbe, Wohnen</li>
                        <li>
                          Neugestaltung Grünanlagen und Parkplatzsituation
                        </li>
                      </ul>
                    </li>
                    <li>
                      Realisierung Stadtmuseum am südlichen Abschluss
                      Sudetenstraße
                    </li>
                    <li>
                      Sanierung und Aufwertung Platz und Kreuzung Sudetenstraße
                      / Teichstraße
                    </li>
                    <li>
                      Sinnvolle Überplanung des Geländes „Kreisbauhofs
                      Bayerwaldstraße“ in Verbindung mit Gestaltung des
                      Parkplattes Ecke Neudecker Straße zu Geschwister Scholl
                      Straße
                    </li>
                    <li>Verflechtungsachse Kaufpark – Innenstadt</li>
                    <li>
                      Sanierung Stadthalle zu modernem, zeitgemäßen
                      Veranstaltungsort
                    </li>
                  </ul>
                </div>{' '}
              </div>
              <div className='col-md-4 offset-md-1'>
                <img
                  className='img-fluid'
                  style={{ width: '100%' }}
                  src='politik/innenstadt.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='jumbotron jumbotron-fluid'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7'>
                <h3 className='mb-3'>Radwege</h3>
                <div className='lead'>
                  <ul>
                    <li>Erstellung Rad- und Fußwegekonzept für Neutraubling</li>
                    <li>
                      Rad- und Fußwege so gestalten, dass sie Benutzer
                      Sicherheit und Schutz geben
                    </li>
                    <li>
                      Radwegring rund um Neutraubling entlang: Südumgehung,
                      Lärmschutzwall A3, Staatsstraße 2145
                    </li>
                    <li>
                      Radwegquerung West–Ost: Borsigstraße, Haidauerstraße,
                      Mintrachinger Straße
                    </li>
                    <li>
                      Radwegquerung Nord–Süd: Pommernstraße, Bayerwaldstraße,
                      Neudeker Straße (Verflechtungsachse Kaufpark – Innenstadt)
                    </li>
                    <li>
                      Radwegverbindung Fürst-Johannes-Ring, Dresdener Straße,
                      Waldenburger Straße, Schulstraße
                    </li>
                    <li>
                      Ausbau Radweg Lehenweg, Laubbergweg, Rainstallweg mit
                      Anschluss an Radweg von Ende Rainstallweg nach Regensburg
                    </li>
                    <li>Radweg am Rainstallweg</li>
                    <li>
                      Radwegeführung durchgängig, gut erkennbar und eindeutig
                      ausschildern
                    </li>
                    <li>
                      Fußwege für Nutzung durch Kinder, Senioren und Menschen
                      mit Handicap optimieren
                    </li>
                  </ul>
                </div>{' '}
              </div>
              <div className='col-md-4 offset-md-1'>
                <img
                  className='img-fluid'
                  style={{ width: '100%' }}
                  src='politik/radwege.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='jumbotron jumbotron-fluid'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7'>
                <h3 className='mb-3'>Umwelt</h3>
                <div className='lead'>
                  <ul>
                    <li>Fernwärmenetz ausbauen</li>
                    <li>
                      Förderung alternativer Energien bei Ausweisung neuer
                      Baugebiete
                    </li>
                    <li>
                      Programm: mehr Bäume für Neutraubling – freie Flächen in
                      städtischer Hand mit Bäumen bepflanzen
                    </li>
                    <li>Waldstreifen entlang Lärmschutzwall A3</li>
                    <li>Grünzonen in neuen Baugebieten</li>
                    <li>
                      Aufwertung Haidpark durch „Barfußweg“ und „Naturlehrpfad“
                    </li>
                    <li>
                      Verlagerung Parkplätze von ebenerdigen Stellflächen in
                      Parkhäuser und Renaturierung von dann nicht mehr
                      benötigten Parkplatzflächen
                    </li>
                    <li>
                      Weiterführung des Energieleitplans für Neutraubling mit
                      zukunftsorientierten Handlungsempfehlungen und Maßnahmen
                    </li>
                  </ul>
                </div>{' '}
              </div>
              <div className='col-md-4 offset-md-1'>
                <img
                  className='img-fluid'
                  style={{ width: '100%' }}
                  src='politik/umwelt.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='jumbotron jumbotron-fluid'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7'>
                <h3 className='mb-3'>Wirtschaft</h3>
                <div className='lead'>
                  <ul>
                    <li>zukunftsorientierter Umgang mit Finanzen</li>
                    <li>
                      Ausweisung von Gewerbeflächen für Klein- und
                      Mittelbetriebe in Handwerk und Dienstleistung
                    </li>
                    <li>
                      Förderung und Unterstützung eines Gründerzentrums
                      Neutraubling für Start-up-Unternehmen, z.B. auf Gelände
                      Kreisbauhof
                    </li>
                  </ul>
                </div>{' '}
              </div>
              <div className='col-md-4 offset-md-1'>
                <img
                  className='img-fluid'
                  style={{ width: '100%' }}
                  src='politik/wirtschaft.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='jumbotron jumbotron-fluid'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7'>
                <h3 className='mb-3'>Soziales</h3>
                <div className='lead'>
                  <ul>
                    <li>
                      Förderung und Unterstützung der Vereine, insbesondere
                      deren Jugend- und Seniorenarbeit
                    </li>
                    <li>
                      zukunftsorientierter Ausbau der Kinderbetreuung und
                      Angebote für junge Familien / Alleinerziehende
                    </li>
                    <li>
                      weiterer Ausbau fachärztlicher Versorgung in Neutraubling
                      und Ausbau fachlicher Betreuung traumatisierter Menschen
                    </li>
                    <li>
                      Unterstützung der sprachlichen und schulischen Förderung
                      von Kindern und Jugendlichen mit Migrationshintergrund
                    </li>
                    <li>
                      Mehrgenerationenhaus mit:
                      <ul>
                        <li>
                          Seniorentreff für regelmäßige Angebote und Treffpunkt
                          für Senioren in Zusammenarbeit mit Vereinen,
                          Einrichtungen und Kirchen
                        </li>
                        <li>
                          Nutzung durch Vereine für Sitzungen, Besprechungen,
                          Tagungen
                        </li>
                        <li>Cafe zur Selbstorganisation</li>
                        <li>
                          integrierter Tagespflege, betrieben durch einen
                          externen Träger
                        </li>
                        <li>
                          Kombination mit einem Kindergarten oder Kinderhort
                        </li>
                      </ul>
                    </li>
                    <li>
                      Aufwertung bestehender Spielplätze mit Spielgeräten und
                      neuen Bewegungsgeräten
                    </li>
                    <li>
                      Verlängerung des Christkindlmarktes und dessen
                      Öffnungszeiten
                    </li>
                  </ul>
                </div>{' '}
              </div>
              <div className='col-md-4 offset-md-1'>
                <img
                  className='img-fluid'
                  style={{ width: '100%' }}
                  src='politik/soziales.svg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  )
}

export default PolitikPage
